import React from "react"
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import { Hero, HeroText } from "../components/Hero"
import { Contact } from "../components/contact-us"
import SEO from "../components/seo"
import { LeftContainer, RightContainer } from "../components/Templates"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO 
      title="Accueil" 
      description="L'agence web proche de vous. 
      Nous vous proposons des services de créations de site web tout compris et de l'accompagnement au numérique."
    />

    <Hero fluid={data.hero.edges[0].node.fluid}>
      <HeroText text="Votre meilleur choix aujourd'hui"/>
    </Hero>

    <LeftContainer
      imageSrc="Saadify/undraw_web_developer_p3e5.png"
      header="Développement web"
      text = {[ "Un ensemble de possibilités pour la création de votre site web.",
                "Que vous souhaitiez un site vitrine simple, la possibilité d'apporter des modifications à nos modèles, une formule tout inclus sous forme d'abonnement ou une offre à la demande, nous sommes présents pour vous accompagner."
               ]}
      button = {["/developpement-web", "En savoir plus"]}
    />

    <RightContainer
      imageSrc="Saadify/SaadDIF_Certification-fondamentaux-marketing-numerique-google.png"
      header="Professionnel certifié"
      text={[ "Prestations réalisées par une professionnel certifié Google Web Marketing",
              "Internet et les réseaux sociaux sont aujourd'hui devenus des plateformes incontournables pour promouvoir une activité, nous vous proposons de vous accompagner et de vous former sur ces nouveaux outils afin de maximiser votre visibilité en ligne et d'atteindre de nouveaux clients."
            ]}
    />

    <LeftContainer
      imageSrc="Saadify/Marianne_Republique_Francaise.png"
      header="Bénéficiez d'aides gouvernementale"
      text = {[ "L'état et les régions mettent à votre disposition un ensemble d'aides pour votre transition numérique.",
                "L'état a annoncé une aide d'un montant de 500€ pour la transformation numérique. La réalisation de votre site web entre complètement dans ce cadre, finalement, la réalisation de votre site web ne vous coûte presque rien!",
                "Les régions proposent également une aide pour soutenir la transition numérique des artisans et commerçants. En Île-de-France cette aide peut aller jusqu'à 1500€. Contactez-nous pour réaliser un devis et profitez de ces aides."
               ]}
    />

    <Contact
      contactButton = {[
        "/contact",
        "Contact"
      ]}
    />

  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    hero: allImageSharp(filter: { original: { src: { regex: "/office-932926_1920/" } } }) {
      edges {
        node {
          id
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
